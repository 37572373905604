.main-doodles{
  position: absolute;
  color: #ff5d73;
  height: 100vh;
  width: 100%;
}

.pageTitle-doodles {

}

.doodleImage {
  width: 70vh;
  margin-top: 10px;
}

.body-description-doodles {
  text-align: center;
  padding: 30px;
}

.description-doodles {
  text-align: center;
  margin: 30px;
  /* display: flex; */
}

.description-text-doodles {
  width: 100vh;
  margin: auto;
}

.arrowImage {
  height: 40px;
}

.backArrow {
  background-color: transparent;
  border: none;
  padding: 20px;
}

.backArrow:hover {
  -webkit-filter: drop-shadow(5px 5px 5px #808080);
  filter: drop-shadow(5px 5px 5px #808080);
}

.animate-left-to-right {
  animation-name: move-left-to-right;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternative;
}

@keyframes move-left-to-right {
  0% {
    transform: translateX(5%);
  }
  50% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(5%);
  }
}

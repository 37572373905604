#main {
	font-family: Pier Sans;
	width: 100%;
	height: 100vh;
	position: absolute;
  color: white;
  z-index: -1;
}

.centered {
  font-size: 40px;
  text-align: center;
  top: 50%;
  position: relative;
}

.scrollArrow {
  animation: bouncing 2s infinite ease-in-out;
  bottom: 0;
  display: block;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
	z-index: 1 !important;
}

.scrollArrow:hover {
  background-color: #292929;
}

@keyframes bouncing {
    0% {bottom: 0;}
    50% {bottom: 20px;}
    100% {bottom: 0;}
}

@media screen and (max-width: 800px) {
	#main {
		padding: 10px;
	}
}

.main-contact {
  position: absolute;
  color: #ff5d73;
  height: 100vh;
  width: 100%;
  /* background: linear-gradient(90deg, #ff5d73 50%, white 50%); */
}

/* .pageTitle-contact {
  font-size: 15vh;
  text-align: center;
  top: 30%;
  position: relative;
} */

.pageTitle-contact {
  position: absolute;
  font-size: 35vh;
  bottom: 0;
  opacity: 0.07;
}

.aboutContainer {
  color: grey;
  height: 50%;
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 5px 10px 18px #888888; */
}

/* .leftHalf {
  height: 100vh;
  width: 50%;
  background-color: #ff5d73;
  opacity: 70%;
} */

.contactIcons {
  top: 50%;
  left: 50%;
  position: relative;
  transform: translate(-150px, -150px);
}

.pic {
  height: 50px;
  padding: 20px;
}

.pic:hover {
  -webkit-filter: drop-shadow(5px 5px 5px #aaaaaa);
  filter: drop-shadow(5px 5px 5px #aaaaaa);
}

.nikitaPic {
  height: 600px;
  position: relative;
  /* float: right;
  bottom: 0px;
  right: 0px; */
}

.outerRing {
  /* top: 0;
  left: 5%; */
}

.innerRing {
  /* top: 30%;
  left: 5%; */
}

.innerCircle {
  /* top: 10%;
  left: 1%; */
}

@media screen and (max-width: 800px) {
  .pageTitle-contact {
    font-size: 10vh;
  }

  .nikitaPic {
    display: none;
  }

  .contactIcons {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 70%
  }
}

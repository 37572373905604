.main-projects {
  position: absolute;
  color: #ff5d73;
  height: 100vh;
  width: 100%;
}

.pageTitle-projects {
  position: absolute;
  font-size: 35vh;
  bottom: 0;
  opacity: 0.07;
}

.projectsContainer {
  height: 50%;
  width: 70%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
  color: white;
  display: flex;
  justify-content: space-evenly;
}

a {
  color: white;
  text-decoration: none;
  width: 33%;
}

@media screen and (max-width: 800px) {
  .projectsContainer {
    flex-direction: column;
    height: unset;
    width: 100%;
    position: absolute;
  }

  .pageTitle-projects {
    font-size: 10vh;
  }

  a {
    width: unset;
  }
}

.projectCard {
  /* background-color: #ff5d73; */
  /* height: 90%; */
  /* width: 25%; */
  margin: 10px;
  text-align: center;
  /* box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) */
}

.projectCard:hover {
  /* box-shadow: 0 10px 16px 0 rgba(0,0,0,1), 0 6px 20px 0 rgba(0,0,0,0.1) */
}

.projectCardDiv {
  /* top: 50%;
  left: 50%;
  position: relative;
  transform: translate(-50%, -70%); */
  transform: translate(0%, 100%);
}

.parallaxContainer:hover {
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)
}

/* .parallaxRender:hover {
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)
} */

@media screen and (max-width: 800px) {
  .projectCardDiv {
    transform: translate(0%, 0%);
  }
}
